<template>
  <div id="app" class="justify-center">
    <v-container fluid :style="containerStyle">
      <v-row align="center" justify="center">
        <v-col cols="12" sm="7">
            <v-img class="justify-center" max-height="500" max-width="300" src="../assets/Logo-Radio-Trunk_blanco.png" />
        </v-col>
        <v-col cols="12" sm="4" align="center" justify="center">
          <v-form>
            <TextFields 
              :Rules='emailRules' 
              :Value='email' 
              Label='Email'
              :Fn='updateEmail'
              Type='text'
              :Enter='login'
              :Dark="true"
              />
            <PasswordFields 
              :Rules='passwordRules' 
              :Value='password' 
              :Show='isVisiblePassword'
              Message='At least 5 characters'
              Label='Password'
              :Fn='updatePassword'
              :appendFn='updateIsVisiblePassword'
              :Enter='login'
              :Dark="true"
            />
            <v-btn @click.prevent="login" outlined rounded dark width="100px">Login</v-btn>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState,mapActions,mapMutations } from "vuex";
import TextFields from '../components/StandardizedComponents/textFields'
import PasswordFields from '../components/StandardizedComponents/passwordFields'
//import Snackbar from '../components/Dialogs/Notifications/snackbar'
export default {
  components:{
    TextFields,
    PasswordFields,
    //Snackbar
  },
  computed: {
    ...mapState('Login',[
      'email',
      'password',
      'containerStyle',
      'emailRules',
      'passwordRules',
      'isVisiblePassword'
    ])
  },
  methods: {
    ...mapActions('Login',[
      'login'
    ]),
    ...mapMutations('Login',[
      'updatePassword',
      'updateEmail',
      'updateIsVisiblePassword'
    ]),
  },
  
  created(){
    this.$store.commit('Login/reziseBackground');
    /*window.addEventListener("resize", function(){
      location.reload()
    });*/
  },
};
</script>

<style scoped>
#app,
#color {
  background: rgb(0, 93, 255);
  background: linear-gradient(
    0deg,
    rgba(0, 93, 255, 1) 0%,
    rgba(21, 40, 71, 1) 100%
  );
}
</style>